<template>
    <div style="width:auto;margin: 0 20px;">
        <!-- 按钮只会在未处理 发布中 已出稿显示 -->
        <el-button type="primary" plain @click="poupShow"
            v-if="(list.order_status == 0 || list.order_status == 1 || list.order_status == 2) && list.premium_status === null">溢价</el-button>
        <div v-else-if="list.premium_status === 1" class="media_premium_box flex">
            <div>
                <p style="color: rgba(222, 13, 13, 1);margin-bottom: 5px;">￥{{ list.premium_price }}</p>

                <template>
                    <el-popconfirm @confirm="update_prmium" cancel-button-text="再想想" title="确认申请退款吗">
                        <el-button slot="reference" type="primary" size="mini" round plain>申请退款</el-button>
                    </el-popconfirm>
                </template>
                <div class="reason_box">
                    <el-tooltip class="item" effect="dark" :content="list.premium_reason" placement="top">
                        <el-button style="border: 0;color: rgba(16, 140, 221, 1);">[查看原因]</el-button>
                    </el-tooltip>
                </div>
            </div>
        </div>

        <div v-else-if="list.premium_status === 0 || list.premium_status === 2 || list.premium_status === 3 || list.premium_status === 4 || list.premium_status === 5"
            class="media_premium_box flex">
            <p style="color: rgba(222, 13, 13, 1);margin-bottom: 5px;">￥{{ list.premium_price }}</p>
            <p v-if="list.premium_status === 0" style="color: rgba(235, 173, 59, 1);">申请溢价中...</p>

            <p v-else-if="list.premium_status === 2" style="color: rgba(224, 25, 25, 1);">
                <el-button type="primary" plain @click="poupShow"
                    style="display: block;margin-bottom: 10px;">再次溢价</el-button>
                拒绝溢价
            </p>

            <p v-else-if="list.premium_status === 3" style="color: rgba(235, 173, 59, 1);">申请退款中...</p>
            <p v-else-if="list.premium_status === 4" style="color: rgba(224, 25, 25, 1);">拒绝退款</p>
            <p v-else="list.premium_status === 5" style="color: rgb(0, 176, 82)">同意退款</p>
            <div class="reason_box" v-if="list.premium_status !== 2">
                <el-tooltip class="item" effect="dark" :content="list.premium_reason" placement="top">
                    <el-button style="border: 0;color: rgba(16, 140, 221, 1);">[查看原因]</el-button>
                </el-tooltip>
            </div>
        </div>
        <p v-else>-</p>
        <div v-show="poup" class="popup" @click.self="poupShow">
            <div class="popup-content">
                <p class="poup_title">稿件标题：{{ list.title }}</p>
                <!-- {{ order_type  }} -->
                <div class="popup_box flex">
                    <p class="popup_p">
                        溢价费用：
                    </p>
                    <el-input @input="change" class="poup_input" type="input" v-model="form.price">
                    </el-input>
                </div>
                <div class="popup_box flex">
                    <p class="popup_p">
                        申请说明：
                    </p>
                    <!-- <el-input class="poup_input" type="input" v-model="form.reason">
                    </el-input> -->
                    <el-input class="poup_input" type="textarea" :rows="4" resize="none" v-model="form.reason">
                    </el-input>
                </div>
                <div class="popup_box flex">
                    <p class="popup_p">
                        注意事项：
                    </p>
                    <p class="poup_text">
                        1.溢价费用会计入订单支付的总价格<br />
                        2.媒介主拒绝你溢价申请会退回您的账户
                    </p>
                </div>
                <div class="flex" style="justify-content: flex-end;">
                    <button class="up_box" @click.stop="poupShow">取消</button>
                    <button class="close_box" @click="confirm">申请溢价</button>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { changeMoney } from '@/util/changeMoney'
import { refresh_user_info } from '@/util/refresh_user_info'

export default {
    props: {
        list: {
            type: Object,
            default: {}
        }
    },
    mounted() {
        // console.log(this.list);
    },
    data() {
        return {
            poup: false,
            form: {
                price: '',
                reason: '',
            }
        }
    },
    methods: {
        change() {
            this.form.price = changeMoney(this.form.price)
        },
        poupShow() {
            // this.form = {
            //     price: '',
            //     reason: '',
            // }
            this.poup = !this.poup
        },
        update_prmium() {
            let data = {}
            data.order_id = this.list.id
            data.id = this.list.premium_id
            data.status = 3
            this.curlPost('/api/users/premium/edit', data).then(res => {
                if (res.data.code) {
                    this.poup = false
                    this.$user_info = refresh_user_info()
                    this.$message({
                        message: '申请成功',
                        type: 'success'
                    })
                    this.$emit('getList')
                }

            })
        },
        confirm() {
            let data = {}
            data.order_id = this.list.id
            data.price = this.form.price
            data.reason = this.form.reason
            this.curlPost('/api/users/premium/save', data).then(res => {
                if (res.data.code) {

                    this.poupShow()
                    this.$user_info = refresh_user_info()
                    this.$message({
                        message: '申请成功',
                        type: 'success'
                    })
                    this.$emit('getList')
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    })
                }
            })
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/poup'
</style>